<template>
  <!-- BD员工列表 -->
  <div class="container" style="margin-top: 15px;">
      <div class="dataOper">
        <div class="OperInput">
          <div class="head">
          <div class="search_box">
            <div class="search_main">
              <div>
                <!-- 点击添加 -->
                <div id="btn">
                  <div class="addBtn" @click="addBd">
                    <img src="../../assets/images/add.png" style="margin-right: 5px;width: 13px;">添加
                  </div>
                </div>
                <el-dialog
                  title="添加"
                  :visible.sync="isShow"
                  width="30%"
                  :modal-append-to-body="false"
                >
                  <el-form
                    ref="form"
                    :model="form"
                    label-width="80px"
                    :rules="rules"
                  >
                    <el-form-item label="手机号" prop="mobile">
                      <el-input v-model="form.mobile"></el-input>
                    </el-form-item>
                    <el-form-item label="备注" sizs="mini" prop="remark">
                      <el-input
                        id="remark"
                        v-model="form.remark"
                        type="textarea"
                      >
                      </el-input>
                    </el-form-item>
                  </el-form>
                  <span slot="footer" class="dialog-footer">
                    <el-button @click="cancel">取 消</el-button>
                    <el-button type="primary" @click="onSubmit"
                      >确 定</el-button
                    >
                  </span>
                </el-dialog>
              </div>
            </div>

            <div class="search_main">
              <div class="status">
                <div>手机号</div>
                <div>
                  <el-input
                    placeholder="请输入内容"
                    v-model="queryinfo.mobile"
                    clearable
                  >
                  </el-input>
                </div>
              </div>
            </div>
            <div class="search_main">
              <div class="status">
                <div>BD姓名</div>
                <div>
                  <el-input
                    placeholder="请输入内容"
                    v-model="queryinfo.realName"
                    clearable
                  >
                  </el-input>
                </div>
              </div>
            </div>
            <div class="search_main">
              <div class="status1" style="display: flex">
                <div class="demandBtn" @click="search">
                  <img src="../../assets/images/search.png" alt="" /> 查询
                </div>
                <div class="resetBtn" @click="reset">
                  <img src="../../assets/images/reset.png" alt="" />重置
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 10px"></div>
    <div class="body">
      <div class="dataTable">
        <el-table
          style="font-family: PingFangRoutine"
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          :header-cell-style="tableHeaderColor"
          :cell-style="{ color: '#2C2C2C', fontSize: '14px' }"
        >
          <el-table-column prop="realName" label="BD姓名"> </el-table-column>
          <el-table-column prop="invokeCode" label="BD邀请码">
          </el-table-column>
          <el-table-column prop="mobile" label="手机号"></el-table-column>
          <el-table-column prop="createTime" label="创建时间"></el-table-column>
          <el-table-column label="备注">
            <template v-slot="scope">
              {{
                scope.row.remark.length > 10 && scope.row.remark.length !== null
                  ? scope.row.remark.substring(0, 10) + "..."
                  : scope.row.remark
              }}
            </template>
          </el-table-column>
          <el-table-column prop="status" label="是否在职">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.status"
                active-color="rgb(64,158,255)"
                inactive-color="rgb(138,138,138)"
                @change="changeComposeStatus(scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template #default="scope">
              <div class="operating">
                <div class="edit" @click="roleedit(scope.row)">
                   <img style="width: 18px;height: 18px;" src="../../assets/images/edit.png"/>
                </div>
                <div class="delete" @click="roledel(scope.row.id)">
                  <i class="el-icon-delete"></i>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div style="height: 10px"></div>
    <div class="footer">
      <!-- 分页 -->
      <div class="Pagination">
        <div class="Pagination_amin">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="1"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
        <div class="Pagination_btn" style="height: 28px;">确定</div>
      </div>
      <!-- 分页 -->
    </div>
    <!-- 编辑备注弹框 -->
    <el-dialog
      title="编辑备注"
      :visible.sync="edit"
      width="30%"
      :modal-append-to-body="false"
    >
      <el-form ref="form" :model="editInfo" label-width="80px" :rules="rules">
        <el-form-item label="备注" sizs="mini" prop="remark">
          <el-input
            id="remark"
            v-model="editInfo.remark"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelEdit">取 消</el-button>
        <el-button type="primary" @click="editSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { bdList, bdAdd, bdEdit, bdDelete, bdEditor, bdGetInfo, bdGetStore } from "@/api/bd";
import "../../font-style/font.css";
export default {
  data() {
    const checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3-9]\d{9}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };

    return {
      queryinfo: {
        mobile: "", //输入框电话号
        realName: "", // 输入框姓名
        currPage:"",
      },
      isShow: false, //控制弹出层
      edit: false, // 编辑弹出层
      editInfo: {
        remark: "",
      },
      tableData: [], // 表格数据
      total: null, // 分页总条数
      form: {
        mobile: "", // 弹窗添加手机号
        remark: "", // 备注
      },
      rules: {
        mobile: [{ required: true, trigger: "blur", validator: checkPhone }],
        remark: [{ max: 50, message: "最多五十位字符", trigger: "blur" }],
      },
      str: "", //备注
    };
  },
  activated() {
    this.list();
    bdGetInfo().then((res) => {});
  },
  methods: {
    tableHeaderColor({ rowIndex }) {
      if (rowIndex === 0) {
        return "background-color: rgba(246, 248, 249, 1);color: #333333;fontSize: '14px'";
      }
    },

    //BD员工列表
    list() {
      bdList(this.queryinfo).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total * 1;
        res.data.list.forEach((item) => {
          if (item.remark !== null) {
            this.str = item.remark.substring(0, 9);
          }
        });
      });
    },

    // 分页
    handleCurrentChange(val) {
      this.queryinfo.currPage = val;
      this.list();
    },

    //搜索
    search() {
      this.total=0
      this.queryinfo.currPage=""
      this.list();
    },

    // 重置
    reset() {
      this.total=0
      this.queryinfo.currPage=""
      this.queryinfo.mobile = "";
      this.queryinfo.realName = "";
      this.list();
    },

    //删除
    async roledel(id) {
      if (!(await this._box())) return;
      bdDelete({ id }).then((res) => {
        let totalPage = Math.ceil((this.total - 1) / 10);
        let currentPage =
          this.queryinfo.currPage > totalPage
            ? totalPage
            : this.queryinfo.currPage;
        this.queryinfo.currPage = currentPage < 1 ? 1 : currentPage;
        this.list();
      });
    },

    //添加按钮显示弹出层
    addBd() {
      // 显示弹出层
      this.isShow = true;
    },

    //弹框确认触发
    onSubmit() {
      this.$refs.form.validate((valid) => {
        // 验证校验结果
        if (!valid) return;
        bdAdd(this.form).then(() => {
          this.list();
          this.removeInfo();
        });
      });
    },

 //取消按钮
    cancel() {
      this.removeInfo();
    },
    //在职离职开关
    changeComposeStatus(val) {
      bdEdit({ id: val.id }).then((res) => {
        this.list();
      });
    },

    // 编辑
    roleedit(val) {
      this.edit = true;
      this.editInfo.remark = val.remark;
      this.userId = val.id;
    },

    // 编辑取消
    cancelEdit() {
      this.edit = false;
      this.editInfo.remark = "";
    },
    //编辑确定
    editSubmit() {
      bdEditor({ id: this.userId, remark: this.editInfo.remark }).then(
        (res) => {
          this.edit = false;
          this.list();
        }
      );
    },
    // 清空输入框关闭弹窗
    removeInfo() {
      this.isShow = false;
      this.form.mobile = "";
      this.form.remark = "";
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/css/common.less";
.head{
  padding-left: 20px;
  padding-top: 7px;
  padding-bottom: 17px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
}
#btn {
  width: 90px;
  margin-right: 50px;
}
/deep/ #remark {
  height: 100px;
  width: 260px;
}
.el-textarea {
  height: 100px;
}
/deep/ .cell{
    text-align: center;
}
/deep/ .OperInput{
    display: flex;
    margin-top: -5px;
}
</style>
